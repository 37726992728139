/* ボタン
----------------------------------------------- */
.c-btn {
    letter-spacing: .05em;
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    padding: 1.5rem 0;
    border-radius: 60px;
    color: #ffffff;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px 0px;

    &:active {
        opacity: 0.4;
    }

    &.c-modal-btn {
        width: 139px;
        position: absolute;
        right: 18px;
        bottom: 15px;
        font-size: 16px;
        &.is-open {
            background-color: #01A69B;
        }
        &.is-standby {
            background-color: #65A5DB;
        }
        &.is-close {
            background-color: #EE9093;
        }
    }
}

.c-modal-cancel__btn {
    position: absolute;
    width: 50px;
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px 0px;
    padding: 5px 0;
    text-align: center;
    background-color: #999;
    border-radius: 20px;
    cursor: pointer;
    top: 52px;
    right: 14px;
    &:active {
        opacity: 0.4;
    }

    &::before {
        position: absolute;
        content: "";
        width: 22px;
        height: 1px;
        border-top: 2px solid #fff;
        transform: rotate(45deg);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    &::after {
        position: absolute;
        content: "";
        width: 22px;
        height: 1px;
        border-top: 2px solid #fff;
        transform: rotate(-45deg);
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;

    }
}
.c-select__cancel-btn {
    position: absolute;
    width: 12px;
    height: 12px;
    text-align: center;
    cursor: pointer;
   top: 0;
   bottom: 0;
    right: 12px;
    margin: auto;
    &:active {
        opacity: 0.4;
    }

    &::before {
        position: absolute;
        content: "";
        width: 12px;
        height: 1px;
        border-top: 1px solid #999;
        transform: rotate(45deg);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

    }

    &::after {
        position: absolute;
        content: "";
        width: 12px;
        height: 1px;
        border-top: 1px solid #999;
        transform: rotate(-45deg);
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;

    }
}