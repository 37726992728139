/*--
js_vue_drag_scroll_wrap
--*/

.js_vue_drag_scroll_wrap {
    overflow: hidden;
    width: 620px;
    line-height: 0;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: grab;
    position: sticky;
    top: 0;
    background: #fff;
}

.js_vue_drag_scroll_wrap:active {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: grabbing;
}

.js_vue_drag_scroll_child {
    display: inline-flex;
    position: relative;
}


/* v-modal */

.c-modal__list .vs__actions,
.c-modal__list-box .vs__actions {
    display: none;
}

.c-modal__select-input.v-select {
    overflow: visible !important;
    width: 155px;
}

.c-modal__select-time .vs__selected {
    position: absolute;
    width: 155px;
    top: 0;
}

.c-modal__list .vs__selected-options {
    width: 235px;
}

.c-modal__list .vs__dropdown-toggle,
.c-modal__select-time .vs__dropdown-toggle {
    border: none;
}

/* .c-modal__list .vs__dropdown-menu{
   overflow-x: hidden;
   overflow-y: scroll;
} */

.c-modal__select-time .vs__dropdown-menu {
    width: 200px;
    max-height: 300px;
}

.p-modal__mail-info.v--modal-overlay .v--modal-box {
    overflow: visible !important;
}

.loading {
    padding: 40px 30px 15px;
}

.loading span {
    line-height: 2;
    font-size: 16px;
}
