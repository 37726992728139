/* ビジュアル
----------------------------------------------- */

.c-room-now {
    display: flex;
    width: 450px;
    height: 320px;
    border-radius: 47px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px 0px;
    transform: translate(-400px);
    overflow: hidden;
    transition: all .6s ease-out;
    position: relative;

    &.is-active {
        transform: translate(-70px);
    }

}

.c-room-now__ttl {
    width: 100%;
    height: 100%;
    >img {
        position: absolute;
        top: 125px;
        right: 0;
        left: 0;
        margin: auto;
    }


    &::before {
        position: absolute;
        content: "";
        width: 242px;
        height: 2px;
        background-color: #fff;
        top: 180px;
        right: 0;
        left: 0;
        margin: auto;
        
    }

    &.is-open {
        background-color: #01A69B;

        >img {
            width: 225px;
            height: 40px;
        }
    }
    &.is-standby {
        background-color: #65A5DB;
           

        >img {
            width: 235px;
            height: 40px;
        }
    }
    &.is-close {
        background-color: #EE9093;

        >img {
            width: 180px;
            height: 40px;
        }
    }
}

.c-room-now__sub-ttl {
    position: absolute;
    top: 190px;
    right: 0;
    left: 0;
    margin: auto;
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}