/* ボックス
----------------------------------------------- */

.c-room-info__box {
    min-width: 285px;
    margin-bottom: 100px;
    margin-right: 20px;
}

.c-shedule__table-box {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    // margin-bottom: 10px;
}

.c-room-now__box {
    // margin-top: 20px;
    // margin-bottom: 35px;
    margin-top: 2.5vh;
    margin-bottom: 1.5vh;
    display: flex;
}

.c-room-now__sub {
    width: 50vw;
    .is-open {
        .c-btn {
            background-color: #01A69B;
        }
    }
    .is-standby {
        .c-room-now__btn-box {
            margin-left: auto;
            margin-right: 0;
            //2つになったら削除対象
        }
        .c-btn {
            background-color: #65A5DB;
        }
    }
    .is-close {
        .c-btn {
            background-color: #EE9093;
        }
    }
}

.c-room-now__btn-wrap {
    display: flex;
}

.c-room-now__btn-box {
    width: 220px;
    &:first-child {
        margin-right: 70px;
    }
}


/* モーダル */

.c-modal__list-box {
    display: flex;
    max-width: 522px;
    margin: 0 auto 30px;
    &.u-check__box {
        margin-bottom: 60px;
    }
}
.c-guest-join__box {
    padding: 4px 0;
    width: 100%;
    max-width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 24px;
    .c-guest-join__name {
        width: 100%;
        min-width: 300px;
        font-size: 15px;
    }
    .c-guest-join__mail {
        width: 100%;
        min-width: 300px;
        font-size: 12px;
    }
}