/* ラベル
----------------------------------------------- */
.c-label {
    padding: 5px 0;
    text-align: center;
    &.c-select__color-label {
        width: 18px;
        height: 18px;
        min-width: 18px;
        border-radius: 50%;
        margin-right: 10px;
        display: block;
        vertical-align: middle;
        &.u-color01 {
            background-color: #337CBA;
        }
        &.u-color02 {
            background-color: #D04545;
        }
        &.u-color03 {
            background-color: #5054BC;
        }
    }
}

.c-label-tooltips-item {
    position: relative;

    .c-label-tooltips {
        display: none;
        position: absolute;
        font-size: 9px;
        bottom:1em;
        z-index: 1000;
        padding: 0.25em;
        color: #FFFFFF;
        background: #B3B3B3;
        z-index: 10;
    }
    &:last-of-type .c-label-tooltips {
        transform: translateX(-1rem);
    }

    &:hover .c-label-tooltips {
        display: block;
    }
}
