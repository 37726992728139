/* 画像
========================================================= */
.u-img-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
  
  &.top {
    object-position: 50% 0%;
    font-family: 'object-fit: cover; object-position: 50% 0%;';
  }
  
  &.bottom {
    object-position: 50% 100%;
    font-family: 'object-fit: cover; object-position: 50% 100%;';
  }
}
