/* カード
----------------------------------------------- */
.c-grid {
    display: flex;
    align-items: center;

    &.align-center {
        justify-content: center;
    }
    &.space-between {
        justify-content: space-between;
    }

    >* {
        width: 100%;
    }


    img {
        width: 100%;
    }

    &.col-2 {
            &>* {
                width: 50%;
            }

        &.m10 {
                &>* {
                    width: calc((100% - 10px) / 2);
            }
        }

        &.m40 {
                &>* {
                    width: calc((100% - 40px) / 2);
                }
        }
    }

    &.col-3 {
            &>* {
                width: 33.33%;
        }

        &.m10 {
                &>* {
                    width: calc((99% - 20px) / 3);
                }
        }
        &.m40 {
                &>* {
                    width: calc((99% - 80px) / 3);
                }
        }
        &.m30 {
                &>* {
                    width: calc((100% - 60px) / 3);
                    margin-right: 30px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
        }
    }

    &.col-4 {
            &>* {
                width: 25%;
            }

        &.m40 {
                &>* {
                    width: calc((100% - 120px) / 4);
                }
        }
    }

}

