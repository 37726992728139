/* テーブル
========================================================= */

.c-shedule__table {
    position: relative;
    
    > dt {
        font-size: 10px;
        color: #666666;
        position: relative;
        height: 20px;
    }
    &:first-of-type {
        padding-left: 10px;
     dd {
        border-left:2px solid #DADCDF ;
        &::before  {
            position: absolute;
            content: "";
            width: 2px;
            height: 10px;
            top: -10px;
            left: -2px;
            background-color: #DADCDF ;
        }
    }
}
    >dd {
        width: 41px;
        height: 96px;
        padding-top: 10px;
        margin-top: 10px;
        border:2px solid #DADCDF ;
        border-left: none;
        position: relative;
        
        &::after  {
            position: absolute;
            content: "";
            width: 2px;
            height: 10px;
            top: -10px;
            right: -2px;
            background-color: #DADCDF ;
        }
    }
}
.c-shedule__table-time {
    position: absolute;
    right: 28px;
}
.c-shedule__table-content {
    width: 100%;
    height: 90%;
    z-index: 1;
    &.u-color01 {
        background-color: #a9ddf3;
    }
    &.u-color02 {
        background-color: #F4777C;
    }
    &.u-color03 {
        background-color: #88D392;
    }
    &.u-color04 {
        background-color: #FDF57A;
    }
}