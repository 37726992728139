/* p-login
----------------------------------------------- */
.p-login {
    position: relative;

    .c-logo__img {
            margin-top: 50px;
            margin-left: 60px;
            max-width: 175px;

        }
    

    .c-login-box {
        margin: auto;
        max-width: 600px;
        width: 90%;
        text-align: center;
        margin: 5rem auto;

    }
    .c-login__ttl {
        font-size: 33px;
        font-weight: bold;
        text-align: center;
    }
    
    .c-login__error-txt {
        margin-top: 15px;
            font-size: 21px;
        font-weight: bold;
        color: #EE9093;
        text-align: center;
        display: none;
    
        &.is-error {
            display: inherit;
        }
    
    }
    .c-input-btn {
        padding: 10px 1em 10px 45px;
        border-radius: 40px;
        width: 100%;
        display: inline-block;
        font-size: 15px;
        letter-spacing: 1.5px;
        box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
        transition: all .3s ease-out;
        cursor: pointer;
        max-width: 500px;
    
    
        &.c-login-btn {
            background-color: #01A69B;
            color: #fff;
            max-width: 175px;
            margin: 3rem auto 0;
            padding: 13px 0;
    
            &:hover {
                transition: all .3s ease-out;
                box-shadow: none;
            }
        }
    
    }
}