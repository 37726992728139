/* アイコン
----------------------------------------------- */
.c-room-info__img {
    margin:0 auto  5px;
    text-align: center;
    &.u-icon01 {
        width: 27px;
        height: 27px;
    }
    &.u-icon02 {
        width: 38px;
        height: 21px;
    }
}
.c-logo__img {
    position: fixed;
    right: 44px;
    top: 15px;
    width: 100px;
    text-align: center;
}

.app-version {
    font-size: .6rem;
}
