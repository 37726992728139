/* リスト
========================================================= */
.c-room-info__list {
    display: flex;
    align-items: center;
    >li {
        margin-right: 7.5px;
        padding-right: 7.5px;
        position: relative;
        &::after {
            position: absolute;
            content: "";
            background-color: #B3B3B3;
            width: 3.5px;
            height: 3.5px;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            border-radius: 50%;
        }
        &:last-child {
            margin-right: 0;
            &::after {
                width: 0;;
            }
        }
    }
}
.c-shedule__info {
    display: flex;
    color: #666;
    min-height: 18vh;
}
.c-shedule__info-list {
    &:first-child {
        margin-right: 50px;
    }
    > dt {
        width: 250px;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 8px;
        border-bottom: 1px solid #666;
    }
    > dd {
        padding: 10px 10px 0;
        max-width: 250px;
    }
}
.c-shedule__info-cont {
    &.is-none {
        margin-top: 10px;
        font-size: 25px;
    }
    
}
.c-shedule__info-ttl {
    font-size: 19px;
}
.c-shedule__group__wrap {
    max-height: 14vh;
    // max-height: 1.5rem;
    overflow: scroll;
    overflow-x: hidden;
}
.c-shedule__group-ttl {
    font-size: 11px;
}
.c-shedule__info-time {
    display: flex;
    align-items: center;
    font-size: 19px;
}
.c-shedule__info-time__in,
.c-shedule__info-time__out {
    font-size: 25px;
    font-weight: 500;
}
/* モーダル */
.c-modal__list-wrap {
    position: relative;
    &:first-child {
        margin-right: 27px;
    }
    &.u-check__list:first-child {
        margin-right: 70px;
    }
}
.c-modal__list {
    max-width: 237.5px;
    >dt {
        margin-bottom: 27px;
    }
}
.c-modal__select-list {
    margin-left: 7px;
    >li {
        display: flex;
        align-items: center;
        position: relative;
        padding-bottom: 7px;
        border-bottom: 1px solid #DFDFDF;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.c-guest-join__list,
.vs__dropdown-menu {
    position: absolute;
    max-width: 320px;
    min-width: 320px;
    z-index: 5001;
    left: auto;
    right: auto;
    top: 50px;
    width: 300px;
    box-sizing: border-box;
    padding: 8px 0;
    border: 1px solid rgba(0, 0, 0, .2);
    background-color: #ffffff;
    border-radius: 0 0 2px 2px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    &.is-active {
        display: inherit;
    }
    > li {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        align-items: center;
        margin-bottom: 5px;

        &:hover {
            background-color: #eee;
        }

        &:last-child {
            margin-bottom: 0;
        }

    }
}

.c-guest-join__img {
    width: 48px;
    padding-left: 16px;
    max-height: 40px;
    height: 24px;

    > img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
}

.c-guest-join__mail {
    font-size: 14px;
    line-height: 24px;
    padding: 4px 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
