/* フォーム
========================================================= */
.c-modal__input {
    width: 100%;
    border-radius: 17px;
    background-color: #F2F3F4;
    input {
        width: 100%;
        padding: 7px 1em;
        cursor: pointer;
        &:focus {
            padding: 7px 1em;
        }
    }
}

.c-modal__select-time {
    display: flex;
    align-items: center;
    width: 384px;
    justify-content: space-around;
    margin: 0 auto;
    font-size: 27px;
    font-weight: 500;
}

.c-modal__select-input {
    input {
        padding-bottom: 5px;
        border-bottom: 2px solid #707070;
        width: 100%;
    }

    &.c-modal__select-time__in,
    &.c-modal__select-time__out {
        position: relative;
        input {
            max-width: 150px;
        }
    }
    &.c-modal__select-time__in {
        left: 0;
    }
    &.c-modal__select-time__out {
        right: 0;
    }
}

.c-modal__select-time__while {
    margin: 0 20px;
}