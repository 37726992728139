/* タブレットモーダル（プロジェクト）
----------------------------------------------- */

.p-modal {
    background-color: #fff;
    width: 100%;
    min-height: 277px;
    position: relative;
    padding: 55px 18px 15px;
    box-shadow:rgba(0, 0, 0, 0.16) 0px 1px 10px 0px;
}