/* テキスト
========================================================= */
.c-room-info__ttl {
    font-size: 40px;
    margin-bottom: 28px;
}

.c-room-info__sub-ttl {
    display: block;
    font-size: 16px;
}

.c-room-info__txt {
    color: #666666;
    font-size: 8px;
}

.c-room-info__people {
    font-size: 14px;
    text-align: center;
}

.c-room-info__number {
    font-size: 28px;
}

.c-room-info__condition {
    font-size: 20px;
    color: #666;
}

.c-shedule__YM {
    font-size: 14px;
    margin-bottom: 2px;
}

.c-shedule__DW {
    font-size: 25px;
    margin-bottom: 10px;
}

.c-room-now__info {
    font-size: 20px;
}

.c-room-now__info {
    font-size: 20px;
    color: #666666;
    // margin: 35px 0;
    margin: 3vh 0;
    min-height: 85px;
    max-width: 450px;
}

.c-room-now__info_time {
    font-size: 36px;
    padding: 0 5px;
}

.c-room-now__btn-ttl {
    margin-bottom: 15px;
    color: #b3b3b3;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}

/*モーダル*/
.c-modal__ttl {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;

    &.u-time__ttl {
        margin-bottom: 35px;
    }
    &.u-check__ttl {
        margin-bottom: 50px;
    }
}

.c-select__name {
    font-size: 11px;
    padding-right: 30px;
    word-break: break-all;
}

.c-error__txt {
    color: #D04545;
    font-weight: bold;
    text-align: center;
    margin: 1em 0;
    display: none;

    &.is-active {
        display: inherit;
    }
}
.c-modal-room__name {
    font-size: 27px;
}
.c-modal-room__sub-name {
    font-size: 16px;
    display: block;
}
.c-modal-meeting__ttl {
    font-size: 30px;
    line-height: 1;
}
.c-modal-meeting__time {
    font-size: 40px;
}
.c-modal-meeting__time-while {
    margin: 0 20px;
}