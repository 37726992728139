/* タブレット（プロジェクト）
----------------------------------------------- */
.p-room-info__wrap {
    display: flex;
    align-items: center;
    margin: 1.25vh auto 0;
    padding: 0 50px;
}

.p-shedule__wrap {
padding-left: 35px;
border-left: 1px solid #707070;
}

.class_meeting_room_wrap {
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
}