@charset "utf-8";

/*=========================================
    RESET
=========================================*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  -webkit-appearance: none;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote::before, blockquote::after, q::before, q::after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
table tr th{
  vertical-align: middle;
}
button {
  border: none;
  cursor: pointer;
}
*, *::before, *::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
address{
  font-style: normal;
}
.clear {
  clear: both;
}
.clearfix::before, .clearfix::after {
  content: " ";
  display: table;
}
.clearfix::after {
  clear: both;
}
.clearfix {
 *zoom: 1;
}
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
input {
  border-radius:0;
  border: none;
  font: inherit;
}